<template>
  <section class="wizard-form container-fluid">
    <div class="row">
      <div class="col-12 col-md form my-2 p-2" style="max-width: 580px">
        <div class="container-icon-shield w-100 text-center">
          <div class="icon px-2">
            <img :src="require('@/assets/images/icons/blue-shield.png')" width="60px" />
          </div>
        </div>

        <div v-if="!provideLaterInfo">
          <!-- Steps -->
          <div class="steps row px-0 px-md-3 mt-3 mb-2">
            <div
              v-for="(item, index) in questions"
              :key="index"
              :class="`col step ${
                index <= step ? 'step-active animate__animated animate__bounceIn' : ''
              }`"
            ></div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="text-center" style="width: 90%">
              <h2 class="mb-1">Supplier Portal</h2>
              <p>
                To complete your address updates with your providers, they require the below
                information 👍
              </p>

              <div class="questions" v-for="(item, index) in this.currentQuestions" :key="index">
                <!-- <div>
                    <h2 class="mb-1 text-left">Question 1</h2>
                    <div class="d-flex mb-2">
                      <div class="box-answers-services mr-1">
                        <img
                          :src="'https://bucket-image-container.s3.amazonaws.com/form/assets/NHS.svg'"
                          width="40"
                          alt="NHS"
                        />
                        <p class="mb-0">NHS</p>
                      </div>
                      <div class="box-answers-services mr-1">
                        <img
                          :src="'https://bucket-image-container.s3.amazonaws.com/form/assets/icons/dentist.svg'"
                          width="40"
                          alt="Dentist"
                        />
                        <p class="mb-0">Dentist</p>
                      </div>
                    </div>
                  </div> -->
                <!-- Select -->
                <div
                  class="mb-3 question"
                  v-if="
                    item.typeInput == 'select' &&
                    (item.hasOwnProperty('showInputOnlyIf') ? item.showInputOnlyIf() : true)
                  "
                >
                  <p class="text-left text-white">{{ item.title }}</p>
                  <vSelect
                    :value="item.value"
                    class="w-100 select"
                    label="title"
                    :clearable="false"
                    :options="item.options"
                    :placeholder="item.title"
                    :multiple="false"
                    @input="(e) => updateValue(e, index, item.valName)"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.title ? option.title : option }}
                    </template>
                  </vSelect>
                  <p
                    class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
                    v-if="item.displayError"
                  >
                    * {{ item["messageError"] }}
                  </p>
                </div>
                <!-- Input Text -->
                <div
                  class="mb-3 question"
                  v-if="
                    item.typeInput == 'text' &&
                    (item.hasOwnProperty('showInputOnlyIf') ? item.showInputOnlyIf() : true)
                  "
                >
                  <p class="text-left text-white">{{ item.title }}</p>
                  <input
                      type="text"
                      :placeholder="item.title"
                      class="w-100 p-1"
                      :value="item.value"
                      @input="(e) => updateValue(item.valName == 'selectedNINO'
                        ? e.target.value.toLocaleUpperCase().replace(' ', '')
                        : e.target.value, index, item.valName)"
                    />
                  <p
                    class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
                    v-if="(item.displayError && item.isRequired) || item.displayError"
                  >
                    * {{ item["messageError"] }}
                  </p>
                  <!-- <p
                    class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
                    v-else-if="item.displayError"
                  >
                    * {{ item["messageError"] }}
                  </p> -->
                  <div v-if="item.hasOwnProperty('isRequired')" class="d-block text-right mt-1">
                    <label class="switch">
                      <input
                        type="checkbox"
                        @change="(payload) => checkClick(index)"
                        :checked="item.isRequired"
                      />
                      <span class="slider round"></span>
                    </label>

                    <p v-if="item.isRequired" class="mb-0">I know it</p>
                    <p v-if="!item.isRequired" class="mb-0">I don't know it</p>
                  </div>
                </div>

                <!-- Date Input -->
                <div class="mb-3 question" v-if="item.typeInput == 'date'">
                  <p class="text-left text-white">{{ item.title }}</p>
                  <!--< Datepicker
                      :value="item.value"
                      :highlighted="{ dates: [new Date(Date.now())] }"
                      @selected="(e) => updateValue(e, index, item.valName)"
                      id="datepicker-1"
                      class="w-100"
                      :disabled-dates="disabledDates()"
                    /> -->

                  <DateMultiSelect
                    :dateModel="item.value"
                    @formattedDate="(value) => updateValue(value, index, item.valName)"
                    :isDOB="item.valName == 'dateOfBirth' ? true : false"
                    ref="dateMultiSelect"
                  />

                  <p
                    class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
                    v-if="item.displayError"
                  >
                    * {{ item["messageError"] }}
                  </p>
                </div>

                <!-- Select Boxes Questions -->
                <div class="mb-3 question" v-if="item.typeInput == 'select-box'">
                  <p class="text-left text-white">{{ item.title }}</p>
                  <div class="d-flex mb-1">
                    <div
                      class="box-answers"
                      :class="{ checked: item.value == option || item.value == option.value }"
                      v-for="(option, i) in item.options"
                      :key="i"
                      @click="
                        (e) =>
                          updateValue(option.title ? option.value : option, index, item.valName)
                      "
                    >
                      {{ option.title ? option.title : option }}
                    </div>
                  </div>
                  <p
                    class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
                    v-if="item.displayError"
                  >
                    * {{ item["messageError"] }}
                  </p>
                </div>
              </div>
              <button
                role="button"
                id="mainButton"
                class="button-sloth mt-1 d-flex justify-content-center mb-2"
                @click="onClickButtonForm()"
                :disabled="sending"
              >
                <h4 class="mb-0 font-weight-bold">CONTINUE</h4>
                <div v-if="sending" class="ml-1 spinner-border text-light" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div v-if="provideLaterInfo" class="d-flex justify-content-center">
          <div class="text-center my-3" style="width: 90%">
            <p class="mb-3">
              Before we can progress your address update, we require answer to all of the questions
              in this section You can leave this page now and your progress will be saved.  Contact
              us at hello@slothmove.com if you require assistance🙂
            </p>

            <button
              role="button"
              class="button-sloth mt-1 d-flex justify-content-center"
              @click="logout()"
            >
              <h4 class="mb-0 font-weight-bold">LOGOUT</h4>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md p-3" style="max-width: 350px">
        <div class="info-portal p-1">
          <h5 class="font-weight-bolder">Your Portal💡</h5>
          <p class="mb-0">
            The questions on the left are the follow-up information your suppliers need to complete
            your address updates. You can select the
            <i class="fa-solid fa-circle-info" style="color: #23a9d7"></i> icon for support with any
            question.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axiosCustom from "@axios";
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { nationalities } from "../lists";
import Datepicker from "vuejs-datepicker";
import useJwt from "@/auth/jwt/useJwt";
import DateMultiSelect from "./DateMultiSelect.vue";
import moment from "moment";

export default {
  name: "WizardForm",
  components: {
    vSelect,
    Datepicker,
    DateMultiSelect,
  },
  data() {
    return {
      provideLaterInfo: false,
      sending: false,
      step: 0,
      questions: [[]],
      currentQuestions: [],
      objMove: {},
      objectValues: {},
    };
  },
  mounted() {
    this.objMove = { ...this.currentMove };
    this.$store.commit("app/setField", ["displayLabelAboutData", false]);

    this.displayQuestions();

    //If there are no questions to be filled in, it means that the user has already provided all the necessary information for this Miniform, we redirect to DVLAMiniform.

    setTimeout(() => {
      let inputsDisplayed = document.getElementsByClassName("question");

      if (inputsDisplayed.length == 0 && !this.provideLaterInfo) {
        let { informDVLA } = this.currentMove.services;

        if (informDVLA) {
          this.$store.commit("app/setField", ["showDVLAMiniForm", true]);
          this.$store.commit("app/setField", ["showWizardForm", false]);
        } else if (!informDVLA) {
          //If the user has no questions to answer and will not change DVLA service, he provide all information and show the whole dashboard.

          this.objMove["allDataProvided"] = true;

          this.submitData(this.objMove)
            .then(async () => {
              const response = await axiosCustom.get(
                `api/user/getDetails?email=${this.user.email}`
              );
              const moves = response.data.moves;
              const move = moves[moves.length - 1];
              this.$store.commit("app/setField", ["currentMove", move]);
              this.$store.commit("app/setField", ["showWizardForm", false]);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      }
    }, 300);

    // let isEqualArrays = (first, second) => JSON.stringify(first) === JSON.stringify(second);
    // if (isEqualArrays(this.questions, [[]]) && this.currentMove.services.informDVLA) {
    // }
    //
  },
  methods: {
    displayQuestions() {
      let info = { ...this.currentMove["councils"], ...this.currentMove["services"] };

      let o = this.objectValues;

      // console.log(info);

      let questionsToDisplay = [];

      if (
        !this.currentMove.moveDetails["newResidentialStatus"] ||
        this.currentMove.moveDetails["newResidentialStatus"] == ""
      ) {
        questionsToDisplay.push(
          //First Question - mandatory
          {
            title: "New Residential Status",
            value: "",
            valName: "newResidentialStatus",
            typeInput: "select",
            options: [
              "I'm renting",
              "I own my home",
              "I'm house sharing",
              "I'm living with parents",
            ],
            validations: function () {
              return this.value == "";
            },
            messageError: "This field is required",
            displayError: false,
            //First Question - mandatory
          }
        );
      }

      if (
        !this.currentMove["moveDetails"].moveInDate ||
        this.currentMove["moveDetails"].moveInDate == ""
      ) {
        questionsToDisplay.push({
          title: "Move In Date",
          value: "",
          valName: "moveInDate",
          typeInput: "date",
          options: null,
          validations: function () {
            return this.value == "";
          },
          messageError: "This field is required",
          displayError: false,
        });
      }

      if (
        !this.currentMove["moveDetails"].moveOutDate ||
        this.currentMove["moveDetails"].moveOutDate == ""
      ) {
        questionsToDisplay.push({
          title: "Move Out Date",
          value: "",
          valName: "moveOutDate",
          typeInput: "date",
          options: null,
          validations: function () {
            return false;
          },
          messageError: "This field is required",
          displayError: false,
        });
      }

      if (this.currentMove.userDetails["contactNumber"] == "") {
        questionsToDisplay.push({
          title: "What's your mobile number?",
          value: "",
          valName: "contactNumber",
          typeInput: "text",
          options: null,
          validations: function () {
            return !(
              (this.value.indexOf("07") == 0 && this.value.length == 11) ||
              (this.value.indexOf("00447") == 0 && this.value.length == 14) ||
              (this.value.indexOf("447") == 0 && this.value.length == 12)
            );
          },
          messageError: "The format of this number is invalid",
          displayError: false,
        });
      }

      if (info["informElectoralRoll"]) {
        if (info["selectedNationalities"]?.length == 0
            || info["selectedNationalities"][0]?.length == 0) {

          questionsToDisplay.push({
            title: "What is your nationality",
            value: [""],
            valName: "selectedNationalities",
            typeInput: "select",
            options: nationalities,
            validations: function () {
              return this.value[0].length == 0;
            },
            messageError: "This field is required",
            displayError: false,
          });
        }

        if (info["selectedOpenRegister"] == "") {
          questionsToDisplay.push({
            title: "For the electoral roll, would you like to be on the open register?",
            value: "",
            valName: "selectedOpenRegister",
            typeInput: "select-box",
            options: ["Yes", "No"],
            validations: function () {
              return this.value == "";
            },
            messageError: "This field is required",
            displayError: false,
          });
        }

        if (!info["selectedPostalVote"]) {
          questionsToDisplay.push({
            title: "Do you want to apply for a postal vote",
            value: "",
            valName: "selectedPostalVote",
            typeInput: "select",
            options: [
              "No, I prefer to vote in person",
              // "No, I already have postal vote",
              "Yes, send me a postal vote application form",
            ],
            validations: function () {
              return this.value == "";
            },
            messageError: "This field is required",
            displayError: false,
          });
        }

        questionsToDisplay.push({
          title: "Where should we send your postal vote application to?",
          value: "",
          valName: "selectedSendingMethod",
          typeInput: "select",
          options: ["Email it to me", "Post it to me"],
          validations: function () {
            return o["selectedPostalVote"] == "Yes, send me a postal vote application form" && this.value == "";
          },
          messageError: "This field is required",
          displayError: false,
          showInputOnlyIf: function () {
            return o["selectedPostalVote"] == "Yes, send me a postal vote application form";
          },
        });
      }

      if (info["informTVLicense"]) {
        if (!info["tvLicenseAction"]) {
          questionsToDisplay.push({
            title: "Select what you need to do with your TV licence account",
            value: "",
            valName: "tvLicenseAction",
            typeInput: "select",
            options: [
              { title: "Update the address on my License", value: "update" },
              { title: "Setup a TV Licence", value: "setup" },
              { title: "Cancel my TV Licence", value: "cancel" },
            ],
            validations: function () {
              return this.value == "";
            },
            messageError: "This field is required",
            displayError: false,
          });
        }

        if (info["tvLicenceNumber"] == "" || !info["tvLicenceNumber"]) {
          questionsToDisplay.push({
            title: "TV Licence number",
            value: "",
            valName: "tvLicenceNumber",
            typeInput: "text",
            options: null,
            validations: function () {
              return this.isRequired && this.value.length !== 10;
            },
            isRequired:true,
            messageError: "The TV licence number must be 10 characters",
            displayError: false,
            showInputOnlyIf: function () {
              return o["tvLicenseAction"]?.value !== "setup";
            },
          });
        }
      }


      if (info['informBanks'] && this.isBanksIncomplete()) {

        for (let i = 0; i < this.currentMove["services"].selectedBanksDetails.length; i++) {

          questionsToDisplay.push({
            title: 'Last four digits of your '+ this.currentMove['services'].selectedBanksDetails[i].name + ' account number',
            value: '',
            valName:"accountNumber-"+i,
            typeInput: 'text',
            options: null,
            validations: function () {
              return this.value.length <4 || this.value.length >4;
            },
            messageError: 'Please write the last four digits of your account number',
            displayError: false,
          })
        }
      }
      if (info['informDentist'] && info.dentistAddress == '' || info.dentistAddress == null) {
        questionsToDisplay.push({
          title: "Your dentist’s surgery name",
          value: "",
          valName: "dentistSurgeryName",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });

        questionsToDisplay.push({
          title: "The first line of your dentist’s address",
          value: "",
          valName: "dentistAddressFirstLine",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });

        questionsToDisplay.push({
          title: "Your dentist’s city/town",
          value: "",
          valName: "dentistCityTown",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });


        questionsToDisplay.push({
          title: "Your dentist’s postcode",
          value: "",
          valName: "dentistPostcode",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });
      }

      const isLessThan17years = (date) => {
        let givenDate = moment(date, "ddd MMM DD YYYY HH:mm:ss ZZ"); // parse the given date
        let now = moment(); // get the current date and time
        let differenceInYears = now.diff(givenDate, "years"); // calculate the difference in years
        return  differenceInYears <= 17 ;
      };

      let dvlaValidationExtra = ( dateOfBirth ) =>{
        // debugger
        return info.informDVLA && isLessThan17years(dateOfBirth) &&
        (this.currentMove.dvlaQuestions.dvlaNeed == "license" ||
          this.currentMove.dvlaQuestions.dvlaNeed == "both");}

      // console.log(info,this.currentMove.userDetails);
      if (
        (info.informDVLA ||
          info.informDentist ||
          info.informLottery ||
          info.informStudentFinance ||
          info.informPension ||
          info.informHMRC ||
          info.informNHS ||
          info.informBonds ||
          info.informDWP ||
          info.informElectoralRoll ||
          info.informBanks ||
          info.informInsurance ||
          info.informPets||
          info.informHomecare||
          info.informBreakdown||
          info.informMobile||
          info.informOpticians||
          info.selectedGyms.length > 0 ||
          (info.informLoyalty && info.selectedLoyalties.some((item) => item == "Boots"))) &&
        !this.currentMove.userDetails["dateOfBirth"]
      ) {
        questionsToDisplay.push({
          title: "Date of Birth",
          value: "",
          valName: "dateOfBirth",
          typeInput: "date",
          options: null,
          validations: function () {

            this.messageError = dvlaValidationExtra(this.value) ? "You need to be over 17 to be entitled to a driving licence. Please contact hello@slothmove.com if you think there is a mistake" :"This field is required"

            return this.value == "" || !this.value || dvlaValidationExtra(this.value)
          },
          messageError: '',
          displayError: false,
        });
      }

      if (info.informNHS && info.gpAddress == '' || info.gpAddress == null) {
        questionsToDisplay.push({
          title: "Your doctor’s surgery name",
          value: "",
          valName: "doctorSurgeryName",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });

        questionsToDisplay.push({
          title: "The first line of your doctor’s address",
          value: "",
          valName: "doctorAddressFirstLine",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });

        questionsToDisplay.push({
          title: "Your doctor’s city/town",
          value: "",
          valName: "doctorCityTown",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });


        questionsToDisplay.push({
          title: "Your doctor’s postcode",
          value: "",
          valName: "doctorPostcode",
          typeInput: "text",
          options: null,
          validations: function () {
              return this.value == ""
          },
          messageError: "This field is required",
          displayError: false,
        });
      }

      if (
        (info.informDVLA ||
          info.informHMRC ||
          info.informDWP ||
          info.informElectoralRoll ||
          info.informStudentFinance ||
          info.informPension) &&
        !info.selectedNINO
      ) {
        questionsToDisplay.push({
          title: "National Insurance Number",
          value: "",
          valName: "selectedNINO",
          typeInput: "text",
          options: null,
          validations: function () {
            if (
              this.value == "" ||
              (this.value.length >= 1 &&
                !/^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1}?\s*$/.test(this.value))
            ) {
              return true;
            } else {
              return false;
            }
          },
          messageError: "Please enter a correct number",
          displayError: false,
        });
      }

      // if ((info.informNewCouncil || info.informOldCouncil) && !info["singleOccupant"]) {
      //   questionsToDisplay.push({
      //     title: "Will you be a single occupant?",
      //     value: null,
      //     valName: "singleOccupant",
      //     typeInput: "select-box",
      //     options: [
      //       { title: "Yes", value: true },
      //       { title: "No", value: false },
      //     ],
      //     validations: function () {
      //       return this.value === null;
      //     },
      //     messageError: "This field is required",
      //     displayError: false,
      //   });
      // }

      questionsToDisplay.forEach((item, index, array) => {
        if (index <= 2) {
          this.questions[0].push(questionsToDisplay[index]);
        } else if (index % 3 == 0) {
          let group = [
            questionsToDisplay[0 + index],
            questionsToDisplay[1 + index],
            questionsToDisplay[2 + index],
          ].filter((item) => item != undefined);
          this.questions.push(group);
        }
      });

      this.currentQuestions = this.questions[this.step];
    },
    displaysErrorsIfExists() {
      return new Promise((resolve, reject) => {
        this.currentQuestions.forEach((item, i, array) => {
          item.displayError = item["validations"] ? item.validations() : false;
          if (array.length - 1 == i) {
            resolve(i);
          }
        });
      });
    },
    async onClickButtonForm() {
      await this.displaysErrorsIfExists();

      let areThereErrors = document.getElementsByClassName("question-error");

      if (areThereErrors.length > 0) {
        this.$store.commit("app/setField", ["displayLabelAboutData", true]);
      }

      if (areThereErrors.length == 0 && this.questions.length - 1 > this.step) {
        try {
          this.$refs.dateMultiSelect[0].clear();
        } catch (e) {
          console.log(e);
        }

        this.setGroupQuestions();
        this.$store.commit("app/setField", ["displayLabelAboutData", false]);
        // return
        this.submitData(this.objMove)
          .then(() => {})
          .catch((error) => console.log("error", error));
      }
      //If these are the last questions and if there are no errors we will submit
      else if (areThereErrors.length == 0 && this.questions.length - 1 == this.step) {
        this.sending = true;

        await this.fillAnswers();

        console.log({ objMove: this.objMove });

        // return

        this.submitData(this.objMove)
          .then(async () => {
            if (this.currentMove.services.informDVLA) {
              this.$store.commit("app/setField", ["showDVLAMiniForm", true]);
              this.$store.commit("app/setField", ["showWizardForm", false]);
            } else {
              const response = await axiosCustom.get(
                `api/user/getDetails?email=${this.user.email}`
              );

              try {
                localStorage.setItem("userData", JSON.stringify(response.data));
              } catch (error) {
              }

              const moves = response.data.moves;
              // console.log(moves);
              const move = moves[moves.length - 1];

              this.$store.commit("app/setField", ["currentMove", move]);

              this.sending = false;

              this.$store.commit("app/setField", ["showWizardForm", false]);
              this.$store.commit("app/setField", ["displayLabelAboutData", false]);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
    submitData(data) {
      return axiosCustom
        .post("user/utils/fillInFormInDashboard", data)
        .then((response) => console.log(response));
    },
    setGroupQuestions() {
      this.step++;
      this.currentQuestions = this.questions[this.step];
    },
    checkClick(index){
      this.currentQuestions[index]['isRequired'] = !this.currentQuestions[index]['isRequired'];
    },
    updateValue(value, index, valName) {
      // console.log("updateValue", value);

      if (valName == "selectedNationalities") {
        this.currentQuestions[index].value = [value];
      } else if (valName == "selectedNINO") {
        this.currentQuestions[index].value = value.replace(" ", "");
      } else {
        this.currentQuestions[index].value = value;
      }

      //to trigger change detection
      this.currentQuestions.splice(this.currentQuestions.length);

      //*objectValues* temporary object taken for dynamic rendering of some inputs
      this.objectValues[valName] = value;
    },
    isBanksIncomplete(){
      let r=false;
      if (this.currentMove.services.selectedBanksDetails!==null){
        let banksSize =  this.currentMove.services.selectedBanksDetails.length;
        for (let i=0; i<banksSize;i++){
          if (this.currentMove.services.selectedBanksDetails[i].accountNumber===""){
            r=true;
          }
        }
      }
      return r;
    },
    async fillAnswers() {
      delete this.objMove["userDetails"];
      //We check if there are any unanswered questions
      let checkQuestions = this.questions.flat();


      let hasNoAnswers = checkQuestions.find((item) => item.value == "" && item.validations() );
      // console.log("have unanswered questions?", hasNoAnswers);
      //We check if there are any unanswered questions

      await new Promise((resolve, reject) => {
        checkQuestions.forEach((element, i, array) => {
          if (this.currentMove.services.selectedBanksDetails !== null && this.currentMove.services.selectedBanksDetails !== undefined) {

            let banksSize = this.currentMove.services.selectedBanksDetails.length
            for (let v = 0; v < banksSize; v++) {
              if (element.valName == 'accountNumber-' + v) {
                this.objMove.services.selectedBanksDetails[v].accountNumber = element.value
              }
            }
          }
          if (element.valName == "moveInDate") {
            this.objMove.moveDetails["moveInDate"] = this.objectValues["moveInDate"];
          } else if (element.valName == "moveOutDate") {
            this.objMove.moveDetails["moveOutDate"] = this.objectValues["moveOutDate"] ?? this.objectValues["moveInDate"];
          } else if (element.valName == "dateOfBirth") {
            this.objMove.userDetailsObject["dateOfBirth"] = element.value;
          } else if (element.valName == "contactNumber") {
            this.objMove.userDetailsObject["contactNumber"] = element.value;
          } else if (element.valName == "singleOccupant") {
            this.objMove.councils["singleOccupant"] = element.value;
          } else if (element.valName == "newResidentialStatus") {
            this.objMove.moveDetails["newResidentialStatus"] = element.value;
          } else if (element.valName == "tvLicenseAction") {
            this.objMove.services["tvLicenseAction"] = element["value"].value;
          } else {
            this.objMove.services[element.valName] = element.value;
          }
          if (array.length - 1 == i) {
            resolve(i);
          }
        });
      });

      this.objMove["allDataProvided"] =
        hasNoAnswers || this.currentMove.services.informDVLA ? false : true;

      // console.log("objMoveToSend", this.objMove);
    },
    disabledDates() {
      let disabledDates = {
        customPredictor: function (date) {
          const today = new Date();

          // const momentJS = moment()
          // momentJS.subtract(100, 'years')

          if (date > today) {
            return true;
          }
        },
      };
      return disabledDates;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage and Vuex Store
      localStorage.removeItem("userData");
      this.$store.commit("app/setField", ["user", {}]);

      localStorage.clear();
      //Reset user plam
      this.$store.commit("app/setField", ["userPlan", { plan: undefined, endDate: "" }]);

      //Reset Move Objects
      this.$store.commit("app/setField", ["currentMove", {}]);
      this.$store.commit("app/setField", ["lastMove", {}]);

      //Reboot Miniform's flow
      this.$store.commit("app/setField", ["showWizardForm", true]);
      this.$store.commit("app/setField", ["showDVLAMiniForm", false]);
      this.$store.commit("app/setField", ["displayLabelAboutData", false]);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
  computed: {
    ...mapState("app", ["user", "tableDataServices", "currentMove"]),
  },
  watch: {
    currentQuestions: function (newVal) {
      // console.log(newVal);
      this.fillAnswers();
    },
    currentMove(newVal) {
      console.log(newVal);
    },
  },
};
</script>
<style lang="scss">
.wizard-form {
  h2,
  h4,
  p {
    color: white;
  }

  p,
  .v-select {
    font-size: 15px;
  }

  .form {
    border: 3px solid #18a8d6;
    background-color:#1e202d ;
    border-radius: 0.428rem;

    .container-icon-shield {
      position: absolute;
      left: 0;
      top: -30px;
      display: flex;
      justify-content: center;

      .icon {
        /*background-color: #1e202d;*/
        border-radius: 15px;
      }
    }
  }

  .step {
    height: 12px;
    margin: 3px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
  }

  .step-active {
    background: white;
    background: linear-gradient(
      90deg,
      rgba(10, 119, 254, 1) 0%,
      rgba(10, 119, 254, 0.4962359943977591) 65%,
      rgba(179, 0, 255, 1) 100%
    );
    transform: perspective(1px) translateZ(0);
  }

  input[type="text"] {
    outline: none;
    border-radius: 0.375rem;
    border: none;
    color: black;
  }

  .question-error {
    font-size: 14px;
    color: #c64a45
  }

  .vdp-datepicker {
    color: black;
    input {
      padding: 1rem !important;
      width: 100% !important;
    }
  }

  .button-sloth {
    width: 90%;
    background-color: #15799b;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 11px 16px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
  }

  .button-sloth:after {
    background-color: #18a8d6;
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .button-sloth:main,
  .button-sloth:focus {
    user-select: auto;
  }

  .button-sloth:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
  }

  .info-portal {
    background-color: white;
    border-radius: 12px;

    h5 {
      color: #23a9d7;
    }

    p {
      color: black;
    }
  }

  .box-answers-services {
    width: 110px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;

    p {
      color: #000;
    }
  }

  .select {
    font-size: 14px;
    .vs__selected {
      color: black;
    }

    .vs__dropdown-toggle {
      padding: 0.6rem !important;
      border-radius: 0.375rem;
      color: black;
      background-color: white;
    }

    .vs__dropdown-menu {
      color: black !important;
    }
  }

  .box-answers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    min-width: 55px;
    margin-right: 10px;
    border: 1px solid transparent;
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989 !important;
    background-color: #1e9d68 !important;
    color: #fff !important;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 23px;
      width: 23px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}
</style>
